"use client";

import Script from "next/script";
import { type FC, useEffect } from "react";

type Props = {
  config?: {
    /** Page spaceid */
    c5?: string;
    /** Age, gender matching. Defaults to `-1` */
    c14?: number;
    /** Comscore.js library URL */
    comscoreJs?: string;
    /** User FPDM */
    fpdm?: string;
    /** User FPID */
    fpid?: string;
  };
};

export const Comscore: FC<Props> = ({ config = {} }) => {
  // add the inline config that needs to be added to the `window.YAHOO` object
  // Next.js Script tag will not add properties to the window object
  useEffect(() => {
    window.YAHOO = window.YAHOO ?? {};
    window.YAHOO.comscore = {
      c14: `${config.c14 || -1}`,
      cs_fpdm: `${config.fpdm || "*null"}`,
      cs_fpdt: "01",
      cs_fpid: `${config.fpid || "*null"}`,
      cs_fpit: "c",
    };
    window._comscore = window._comscore || [];
    window._comscore.push({
      c5: `${config.c5 || ""}`,
    });
  }, [config]);

  return <Script src={config.comscoreJs} strategy="lazyOnload" />;
};
